<template>
    <div>
    <div class="sub-title">
        <span>疾病监测预警</span> 
        <span style="color: red;">(注:每旬只支持设置三个事项)</span> </div>
        <el-button type="primary" style="min-width: 88px;" @click="addDiseaseData">新增</el-button>
        <el-table border :data="tableData" class="mt20">
            <el-table-column prop="healthyMonitorDate" label="疾病监测日期"> </el-table-column>
            <el-table-column prop="healthyMonitorType" label="疾病监测类型">
                <template slot-scope="scope">
                    {{scope.row.healthyMonitorType + '' == '1'? '已打': scope.row.healthyMonitorType + '' == '2'? '预警': '治疗'}}
                </template>

            </el-table-column>
            <el-table-column prop="healthyMonitorDesc" label="描述"></el-table-column>
            <el-table-column prop="name" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <!-- <el-row class="ta_c">
            <el-button type="primary" class="mt20" style="min-width: 88px;" @click="onSaveFootInfo">保存</el-button>
        </el-row> footerPageInfo -->

        <el-dialog title="新增" width="500px" :visible.sync="dialog">
            <el-form  label-width="120px">
                <el-form-item label="疾病监测日期">
                    <el-date-picker
                        style="width: 100%;"
                        v-model="form.healthyMonitorDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="监测类型" >
                   <el-select style="width: 100%" v-model="form.healthyMonitorType" placeholder="请选择">
                        <el-option
                            v-for="optionsItem in options"
                            :key="optionsItem.value"
                            :label="optionsItem.label"
                            :value="optionsItem.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="疾病监测描述">
                    <el-input v-model="form.healthyMonitorDesc" maxlength="5" show-word-limit autocomplete="off"></el-input>
                </el-form-item>
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            dialog: false,
            options: [{
                value: '1',
                label: '已打'
            }, {
                value: '2',
                label: '预警'
            }, {
                value: '3',
                label: '治疗'
            }],
            form: {
                healthyMonitorDate: '',
                healthyMonitorType: '1',
                healthyMonitorDesc: ''
            }
        }
    },

    mounted(){
        this.getDisease()
    },
    methods:{
        getDisease() {
            //查询预警列表
            this.qa.queryForPageListDisease(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.getDisease()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getDisease()
        },
        addDiseaseData() {
            this.form = {
                healthyMonitorDate: '',
                healthyMonitorType: '1',
                healthyMonitorDesc: ''
            }
            this.dialog = true
        },
        addSubmit() {
            this.qa.doInsertDisease(this.form).then(res => {
                this.$message({
                    message: '保存成功',
                    type: 'success'
                });
                this.dialog = false;
                this.getDisease()
            })
        },
        remove(id) {
            this.qa.doDeleteByIdDisease({id: id}).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getDisease()
            })
        }
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
</style>