<template>
    <div>
    <div class="sub-title">摄像头关联1</div>
        <el-button type="primary" style="min-width: 88px;" v-if="tableData.length < 4" @click="add">关联</el-button>
        <el-table border :data="tableData" class="mt20">
            <el-table-column prop="subjectionEnterprise" label="所属企业"></el-table-column>
            <el-table-column prop="installArea" label="安装区域"></el-table-column>
            <el-table-column prop="deviceUrl" label="URL"></el-table-column>
            <el-table-column prop="deviceFactory" label="使用厂家"></el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column prop="name" label="操作" align="center">
                <template slot-scope="scope">
                    <el-popconfirm
                        icon="el-icon-info"
                        icon-color="red"
                        @confirm="remove(scope.row.id)"
                        title="是否确认取消关联?"
                    >
                        <el-button slot="reference"  type="text" size="small">取消关联</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <!-- <el-row class="ta_c">
            <el-button type="primary" class="mt20" style="min-width: 88px;" @click="onSaveFootInfo">保存</el-button>
        </el-row> footerPageInfo -->

        <el-dialog title="关联摄像头" class="pop" width="500px" :visible.sync="dialog">
            <el-form :model="form" label-width="120px" :rules="rules" ref="cdForm">
                <el-form-item label="关联摄像头" prop="id">
                    <el-select style="width: 100%;" v-model="form.deviceId" placeholder="请选择">
                        <el-option
                            v-for="item in cameraList"
                            :key="item.id"
                            :label="item.subjectionEnterprise + '-' + item.installArea"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            cameraList: [],
            title: '新增',
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
                datascreenNo: '09', //03马铃薯大屏
                deviceType: 'CAMERA',
                positionType: '1', //区分大屏摄像头位置
                
            },
            tableData: [],
            dialog: false,
            form: {
                deviceId:'',
            },
            rules: {
                deviceId: {
                    required: true,
                    message: "请选择关联摄像头",
                    trigger: "change",
                },
                
                
            },
        }
    },

    mounted(){
        this.$nextTick(() => {
            this.getInfo()
        })
        
    },
    methods:{
        getInfo() {
            this.pageInfo.useEnterprise = this.$parent.useEnterprise
            //查询
            this.qa.datascreenDeviceEnterpriseRelationueryForPageList(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
            this.pageInfo.pageSize = page;
            this.getInfo()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getInfo()
        },
        edit(row) {
            this.title = '修改'
            this.form = JSON.parse(JSON.stringify(row))
            this.dialog = true
        },
        add() {
            //查询所有摄像头
            this.qa.queryForPageListiotCamera({pageSize: 9999}).then((res) => {
                if (res.respCode == "0000") {
                    this.cameraList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
            this.form = {
                deviceId: '',
                datascreenNo: '09', //03马铃薯大屏
                positionType: '1', //区分大屏摄像头位置
                useEnterprise: '栖然数字化散养蛋鸡园',
                deviceType: 'CAMERA',
            }
            this.dialog = true
        },
        addSubmit() {
            this.$refs.cdForm.validate((valid) => {
                if (valid) {
                    this.qa.datascreenDeviceEnterpriseRelationDoInsert(this.form).then(res => {
                        this.$message({
                            message: '关联成功',
                            type: 'success'
                        });
                        this.dialog = false;
                        this.getInfo()
                    })
                } 
            })
        },
        remove(id) {
            this.qa.datascreenDeviceEnterpriseRelatiDoDeleteById({id: id}).then(res => {
                this.$message({
                    message: '取消关联成功',
                    type: 'success'
                });
                this.getInfo()
            })
        }
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    
    .pop >>> .el-time-spinner {
        box-sizing: border-box;
        padding-left: 20px;
    }
</style>