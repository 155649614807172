<template>
    <div>
        <div class="setting_content">
            <div class="setting_title" style="border-bottom: 1px solid #EFEFEF;">
                鸡蛋大屏设置
            </div>
            <div class="setting_content_content">
                <div>
                    <div class="sub-title">基本信息</div>
                    <el-form :inline="true" label-width="150px" :model="formInline" class="demo-form-inline">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="服务面积（万亩）">
                                    <el-input v-model="basicInformation.serviceArea"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="喂养鸡蛋（只）">
                                    <el-input v-model="basicInformation.eggCount"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="企业（家）">
                                    <el-input v-model="basicInformation.mapEnterpriseCount"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="总占地(亩)">
                                    <el-input v-model="basicInformation.qiranRoomArea"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="喂养鸡蛋（只）">
                                    <el-input v-model="basicInformation.qiranChickrenCount"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="已产鸡蛋（只）">
                                    <el-input v-model="basicInformation.qiranEggLayingCount"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="日均产蛋（颗）">
                                    <el-input v-model="basicInformation.qiranEggDayCount"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- <el-row class="">
                            <el-col :span="6">
                                <el-form-item label="基地画面名称1">
                                    <el-input v-model="basicInformation.qiranJidiVedio1Title"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="基地实时画面1">
                                    <el-input style="width: 700px" v-model="basicInformation.qiranJidiVedio1Url"></el-input>
                                </el-form-item>
                            </el-col>
                            
                        </el-row>
                        <el-row class="">
                            <el-col :span="6">
                                <el-form-item label="基地画面名称2">
                                    <el-input v-model="basicInformation.qiranJidiVedio2Title"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="基地实时画面2">
                                    <el-input style="width: 700px" v-model="basicInformation.qiranJidiVedio2Url"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="">
                            <el-col :span="6">
                                <el-form-item label="基地画面名称3">
                                    <el-input v-model="basicInformation.qiranJidiVedio3Title"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="基地实时画面3">
                                    <el-input style="width: 700px" v-model="basicInformation.qiranJidiVedio3Url"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="">
                            <el-col :span="6">
                                <el-form-item label="基地画面名称4">
                                    <el-input v-model="basicInformation.qiranJidiVedio4Title"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="基地实时画面4">
                                    <el-input style="width: 700px" v-model="basicInformation.qiranJidiVedio4Url"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="">
                            <el-col :span="6">
                                <el-form-item label="鸡舍内外监控名称1">
                                    <el-input v-model="basicInformation.qiranJidiInoutRoomVedio1Title"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="鸡舍内外实时监控1">
                                    <el-input style="width: 700px" v-model="basicInformation.qiranJidiInoutRoomVedio1Url"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="">
                            <el-col :span="6">
                                <el-form-item label="鸡舍内外监控名称2">
                                    <el-input v-model="basicInformation.qiranJidiInoutRoomVedio2Title"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="鸡舍内外实时监控2">
                                    <el-input style="width: 700px" v-model="basicInformation.qiranJidiInoutRoomVedio2Url"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row> -->
                        <el-row class="">
                            <el-form-item label="3D宣传视频">
                                <el-upload
                                    class="avatar-uploader"
                                    action="auth-web/uploadFileOSS"
                                    accept=".mp4"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess3D"
                                    :before-upload="beforeAvatarUploadMap">
                                    <video  v-if="basicInformation.qiranPublicityVideoUrl" :src="basicInformation.qiranPublicityVideoUrl" class="avatar"></video>
                                    <div v-else class="ta_c">
                                        <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                                        <!-- <div class="ta_c hint mt10 mb5">支持jpg/png格式 </div> -->
                                        <div class="ta_c hint">仅支持mp4格式上传</div> 
                                    </div>
                                </el-upload>
                                <!-- <el-input style="width: 700px" v-model="basicInformation.qiranPublicityVideoUrl"></el-input> -->
                            </el-form-item>
                        </el-row>
                        <el-row class="">
                            <el-form-item label="一筐蛋地图地址">
                                <!-- accept=".mp4" -->
                                <el-upload
                                    class="avatar-uploader"
                                    action="auth-web/uploadFileOSS"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccessMap"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="basicInformation.qiranYikuangdanMapUrl" :src="basicInformation.qiranYikuangdanMapUrl" class="avatar">
                                    <div v-else class="ta_c">
                                        <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                                        <!-- <div class="ta_c hint mt10 mb5">支持jpg/png格式 </div> -->
                                        <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                                        <div class="ta_c hint"> RGB模式，不超过10M</div>
                                    </div>
                                </el-upload>
                                <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanMapUrl"></el-input> -->
                            </el-form-item>
                        </el-row>
                        <el-row class="">
                            <el-form-item label="一筐蛋二维码地址">
                                <el-upload
                                    class="avatar-uploader"
                                    action="auth-web/uploadFileOSS"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="basicInformation.qiranYikuangdanQrcodeUrl" :src="basicInformation.qiranYikuangdanQrcodeUrl" class="avatar">
                                    <div v-else class="ta_c">
                                        <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                                        <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                                        <div class="ta_c hint"> RGB模式，不超过10M</div>
                                    </div>
                                </el-upload>
                                <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                            </el-form-item>
                        </el-row>
                        <el-row class="">
                            <el-form-item label="一筐蛋简介">
                                <el-input type="textarea" style="width: 700px" v-model="basicInformation.qiranYikuangdanContent"></el-input>
                            </el-form-item>
                        </el-row>
                        <el-row class="ta_c">
                            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
                        </el-row>
                    </el-form>
                </div>
                <div class="line"></div>
                <!-- ---------------------------------------------- -->
                <div>
                    <div class="sub-title">蛋鸡产蛋统计数据</div>
                    <el-button type="primary" style="min-width: 88px;" @click="addEgg">新增</el-button>
                    <el-table border :data="eggList" class="mt20">
                        <el-table-column prop="eggLayingDate" label="选择日期"></el-table-column>
                        <el-table-column prop="eggLayingCount" label="鸡蛋个数(颗)"></el-table-column>
                        <el-table-column prop="name" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" @click="removeEgg(scope.row.id)" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="overflow: hidden;">
                        <div style="margin-top: 12px; float: right">
                            <el-pagination
                                @size-change="handleSizeChangeEgg"
                                @current-change="handleCurrentChangeEgg"
                                :current-page="eggPageInfo.pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="eggPageInfo.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="eggPageInfo.total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
                <el-dialog title="新增" width="500px" :visible.sync="eggDialog">
                    <el-form  label-width="80px">
                        <el-form-item label="选择时间">
                            <el-date-picker
                                style="width: 100%;"
                                v-model="eggForm.eggLayingDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="下单数量" prop="userBO.userName">
                            <el-input v-model="eggForm.eggLayingCount" autocomplete="off"></el-input>
                        </el-form-item>
                        <div class="dialog-footer" style="text-align: right">
                            <el-button @click="eggDialog = false">取 消</el-button>
                            <el-button type="primary" @click="addEggSubmit">确 定</el-button>
                        </div>
                    </el-form>
                </el-dialog>
                <div class="line"></div>
                <!-- ---------------------------------------------- -->
                <div >
                    <div class="sub-title">蛋鸡实时AIOT智能脚环数据</div>
                    <el-button type="primary" style="min-width: 88px;" @click="addEggFoot">新增</el-button>
                    <el-table border :data="footerList" class="mt20">
                        <el-table-column prop="footRingName" label="脚环名称"> </el-table-column>
                        <el-table-column prop="footRingDeviceInfo" label="脚环设备信息"></el-table-column>
                        <el-table-column prop="footRingCurDayCount" label="脚环单日步数"></el-table-column>
                        <el-table-column prop="footRingAllCount" label="脚环总步数"></el-table-column>
                        <el-table-column prop="name" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" @click="removeEggFoot(scope.row.id)" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="overflow: hidden;">
                        <div style="margin-top: 12px; float: right">
                            <el-pagination
                                @size-change="handleSizeChangeFooter"
                                @current-change="handleCurrentChangeFooter"
                                :current-page="footerPageInfo.pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="footerPageInfo.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="footerPageInfo.total">
                            </el-pagination>
                        </div>
                    </div>


                    <!-- <el-row class="ta_c">
                        <el-button type="primary" class="mt20" style="min-width: 88px;" @click="onSaveFootInfo">保存</el-button>
                    </el-row> footerPageInfo -->
                </div>
                <el-dialog title="新增" width="500px" :visible.sync="footerDialog">
                    <el-form  label-width="120px">
                        <el-form-item label="脚环名称">
                            <el-input v-model="footerForm.footRingName" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="脚环设备信息" >
                            <el-input v-model="footerForm.footRingDeviceInfo" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="脚环单日步数">
                            <el-input type="number" v-model="footerForm.footRingCurDayCount" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="脚环总步数">
                            <el-input type="number" v-model="footerForm.footRingAllCount" autocomplete="off"></el-input>
                        </el-form-item>
                        <div class="dialog-footer" style="text-align: right">
                            <el-button @click="footerDialog = false">取 消</el-button>
                            <el-button type="primary" @click="addFooterSubmit">确 定</el-button>
                        </div>
                    </el-form>
                </el-dialog>

                <div class="line"></div>
                <!-- ---------------------------------------------- -->
                <div >
                    <DiseaseData></DiseaseData>
                    <!-- <el-form :inline="true" label-width="150px"  class="demo-form-inline mt20">
                        <div class="disease-box mb5" v-for="(item, index) in diseaseData" :key="index">
                            <i class="el-icon-close fs20 cp" @click="closeDiseaseData(index)"></i>
                            <el-row>
                                <el-form-item label="月份：">
                                    <el-input style="width: 100px" v-model="item.month"></el-input>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="上旬：">
                                    <div class="flex f_ai_c">
                                        <div class="flex f_ai_c" v-for="(subItem, j) in item.early" :key="j">
                                            <el-select style="width: 100px" v-model="subItem.type" placeholder="请选择">
                                                <el-option
                                                    v-for="optionsItem in options"
                                                    :key="optionsItem.value"
                                                    :label="optionsItem.label"
                                                    :value="optionsItem.value">
                                                </el-option>
                                            </el-select>
                                            <el-input class="ml5" maxlength="5" show-word-limit style="width: 120px" v-model="subItem.name"></el-input>
                                            <div class="ml10 cp mr20" style="color: #F7C400;" @click="removeDiseaseData(index, j, 'early')" >删除</div>
                                        </div>
                                        <div class="ml10 cp" style="color: #F7C400;" v-if="item.early.length < 3"  @click="addDiseaseDataItem(index, j, 'early')">+添加数据</div>
                                    </div>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="中旬：">
                                    <div class="flex f_ai_c">
                                        <div class="flex f_ai_c" v-for="(subItem, j) in item.middle" :key="j">
                                            <el-select style="width: 100px" v-model="subItem.type" placeholder="请选择">
                                                <el-option
                                                    v-for="optionsItem in options"
                                                    :key="optionsItem.value"
                                                    :label="optionsItem.label"
                                                    :value="optionsItem.value">
                                                </el-option>
                                            </el-select>
                                            <el-input class="ml5" maxlength="5" show-word-limit style="width: 120px" v-model="subItem.name"></el-input>
                                            <div class="ml10 cp mr20" style="color: #F7C400;" @click="removeDiseaseData(index, j, 'middle')">删除</div>
                                        </div>
                                        <div class="ml10 cp" style="color: #F7C400;" v-if="item.middle.length < 3" @click="addDiseaseDataItem(index, j, 'middle')">+添加数据</div>
                                    </div>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="下旬：">
                                    <div class="flex f_ai_c">
                                        <div class="flex f_ai_c" v-for="(subItem, j) in item.late" :key="j">
                                            <el-select style="width: 100px" v-model="subItem.type" placeholder="请选择">
                                                <el-option
                                                    v-for="optionsItem in options"
                                                    :key="optionsItem.value"
                                                    :label="optionsItem.label"
                                                    :value="optionsItem.value">
                                                </el-option>
                                            </el-select>
                                            <el-input class="ml5" maxlength="5" show-word-limit style="width: 120px" v-model="subItem.name"></el-input>
                                            <div class="ml10 cp mr20" style="color: #F7C400;" @click="removeDiseaseData(index, j, 'late')" >删除</div>
                                        </div>
                                        <div class="ml10 cp" style="color: #F7C400;" v-if="item.late.length < 3"  @click="addDiseaseDataItem(index, j, 'late')">+添加数据</div>
                                    </div>
                                </el-form-item>

                            </el-row>
                        </div>
                    </el-form>
                    <el-row class="ta_c mt20">
                        <el-button type="primary" style="min-width: 88px;" @click="onSaveDisease">保存</el-button>
                    </el-row> -->
                </div>

                <!-- ---------------------------------------------- -->
                <div class="line"></div>
                <CameraList1 ref="cameraList1"></CameraList1>
                <div class="line"></div>
                <CameraList2 ref="cameraList2"></CameraList2>
                <div class="line"></div>
                <IotSoilList ref="iotSoilList"></IotSoilList>
                <div class="line"></div>
                <IotMeteorologicalList ref="iotMeteorologicalList"></IotMeteorologicalList>
                <div class="line"></div>
                <TownsMapTableLsit></TownsMapTableLsit>
            </div>
        </div>
        
        <!-- <div class="bootom">
            <el-button type="primary" @click="save" class="pt6 pb6">保存</el-button>
        </div> -->
    </div>
</template>

<script>
import DiseaseData from './components/egg/diseaseData.vue'
import CameraList1 from './components/egg/cameraList1.vue'
import CameraList2 from './components/egg/cameraList2.vue'
import IotMeteorologicalList from './components/egg/iotMeteorologicalList.vue'
import TownsMapTableLsit from './components/egg/townsMapTableLsit.vue'
import IotSoilList from './components/egg/iotSoilList.vue'
export default {
    data() {
        return {
            //基础信息
            basicInformation: { },
            // 蛋鸡产蛋统计数据
            formInline: {},
            eggList: [],
            eggPageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            eggDialog: false,
            eggForm: {
                eggLayingDate: '',
                eggLayingCount: '',
            },
            ///////
            footerDialog: false,
            footerList: [],
            footerForm: {
                footRingName: '',
                footRingDeviceInfo: '',
                footRingCurDayCount: '',
                footRingAllCount: '',
            },
            footerPageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },



            options: [{
                value: '1',
                label: '已打'
            }, {
                value: '2',
                label: '预警'
            }, {
                value: '3',
                label: '治疗'
            }],
            value: '',
            diseaseData: [{
                month: '11月',
                early: [{ //上旬 
                    type: '2',
                    name: '新城疫',
                },{
                    type: '2',
                    name: '+传支',
                },{
                    type: '2',
                    name: '+减蛋',
                }],
                middle: [{ //中旬 
                    type: '2',
                    name: '新城疫',
                }],
                late: [] //下旬 
            },{
                month: '12月',
                early: [],
                middle: [{
                    type: '3',
                    name: '新城疫',
                }],
                late: []
            },{
                month: '1月',
                early: [{
                    type: '1',
                    name: '新城疫',
                }],
                middle: [{
                    type: '1',
                    name: '新城疫',
                }],
                late: []
            },{
                month: '2月',
                early: [],
                middle: [],
                late: []
            },{
                month: '3月',
                early: [{
                    type: '1',
                    name: '新必安',
                }],
                middle: [],
                late: []
            },{
                month: '4月',
                early: [],
                middle: [],
                late: [{
                    type: '3',
                    name: '',
                }]
            },{
                month: '5月',
                early: [],
                middle: [{
                    type: '1',
                    name: '新城疫',
                }],
                late: []
            },{
                month: '6月',
                early: [],
                middle: [],
                late: []
            },{
                month: '7月',
                early: [],
                middle: [{
                    type: '2',
                    name: '新城疫',
                },{
                    type: '2',
                    name: '+传支',
                }],
                late: []
            },{
                month: '8月',
                early: [],
                middle: [],
                late: []
            },{
                month: '9月',
                early: [],
                middle: [{
                    type: '3',
                    name: '',
                }],
                late: []
            },{
                month: '10月',
                early: [{
                    type: '1',
                    name: '新城疫',
                },{
                    type: '1',
                    name: '+传支',
                },],
                middle: [{
                    type: '1',
                    name: '禽流感',
                },],
                late: []
            }]
        }
    },
    components: {
        DiseaseData,
        CameraList1,
        CameraList2,
        IotSoilList,
        IotMeteorologicalList,
        TownsMapTableLsit
    },
    mounted(){
        this.getInfo()
        this.getEgg()
        this.getFooter()
    },
    methods: {
        //查询信息
        getInfo() {
            this.qa.loadDataBO({}).then(res => {
                if(res.respCode == '0000') {
                    this.basicInformation = res.data;
                }
            })
        },
        getEgg() {
            //查询鸡蛋统计
            this.qa.queryForPageListEgg(this.eggPageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.eggList = res.data;
                    this.eggPageInfo.total = res.total;
                }
            })
        },
        getFooter() {
            //查询鸡蛋统计
            this.qa.queryForPageListFooter(this.footerPageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.footerList = res.data;
                    this.footerPageInfo.total = res.total;
                }
            })
        },
         
    ///////////////////////////
        //保存基本信息
        onSaveInfo() {
            this.qa.doSaveInfo(this.basicInformation).then(res => {
                this.$message({
                    message: '保存成功',
                    type: 'success'
                });
                this.getInfo()
            })
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.basicInformation.qiranYikuangdanQrcodeUrl = res.data.url
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 10;

            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 10MB!');
            }
            return  isLt2M;
        },
        handleAvatarSuccessMap(res, file) {
            console.log(res)
            this.basicInformation.qiranYikuangdanMapUrl = res.data.url
        },
        beforeAvatarUploadMap(file) {
            const isJPG = file.type === 'video/mp4';
            //const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isJPG) {
              this.$message.error('上传视频只能是 mp4 格式!');
            }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 10MB!');
            // }
            // return  isLt2M;
        },
        handleAvatarSuccess3D(res, file) {
            if(res.respCode == '0000') {
                this.basicInformation.qiranPublicityVideoUrl = res.data.url
            }else {
                this.$message.error("上传失败！");
            }
            
        },
    //////////////////////////////////////////////////////////////
        //新增 蛋鸡鸡蛋统计数据
        addEgg() {
            this.eggDialog = true;
            this.eggForm = {
                eggLayingDate: '',
                eggLayingCount: '',
            }
        },
        //保存鸡蛋统计信息
        addEggSubmit() {
            if(this.eggForm.eggLayingDate == '') {
                this.$message.error("请选择日期！");
                return
            }
            if(this.eggForm.eggLayingCount == '') {
                this.$message.error("请输入数量！");
                return
            }
            this.qa.doInsertEgg(this.eggForm).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.eggDialog = false;
                    this.getEgg()
                }
            })
        },
        //删除 蛋鸡鸡蛋统计数据
        removeEgg(id) {
            this.qa.doDeleteByIdEgg({id: id}).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getEgg()
                }
            })
            
        },
        handleSizeChangeEgg(page){
          this.eggPageInfo.pageSize = page;
          this.getEgg();
        },
        handleCurrentChangeEgg(page) {
            this.eggPageInfo.pageNo = page;
            this.getEgg();
        },
//////////////////////////////////////////////////////////////
        //蛋鸡实时AIOT只能脚环数据新增保存
        addFooterSubmit() {
          this.qa.doInsertFooter(this.footerForm).then(res => {
            this.$message({
                message: '保存成功',
                type: 'success'
            });
            this.footerDialog = false;
            this.getFooter()
          })
        },
        //蛋鸡实时AIOT只能脚环数据-新增
        addEggFoot() {
            this.footerForm = {
                footRingName: '',
                footRingDeviceInfo: '',
                footRingCurDayCount: '',
                footRingAllCount: '',
            }
            this.footerDialog = true
        },
        //蛋鸡实时AIOT只能脚环数据-删除
        removeEggFoot(id) {
            this.qa.doDeleteByIdFooter({id: id}).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getFooter();
            })
        },
        handleSizeChangeFooter(page){
            this.footerPageInfo.pageSize = page;
            this.getFooter();
        },
        handleCurrentChangeFooter(page) {
            this.footerPageInfo.pageNo = page;
            this.getFooter();
        },
//////////////////////////////////////////////////////////////
        //保存疾病预警
        onSaveDisease() {

        },
        closeDiseaseData(index) {
            this.diseaseData.splice(index, 1)
        },
        removeDiseaseData(index, j, str) {
            if(str == 'early') this.diseaseData[index].early.splice(j, 1)
            if(str == 'middle') this.diseaseData[index].middle.splice(j, 1)
            if(str == 'late') this.diseaseData[index].late.splice(j, 1)
        },
        addDiseaseDataItem(index, j, str) {
            var obj = {
                type: '1',
                name: '',
            }
            if(str == 'early') this.diseaseData[index].early.push(obj)
            if(str == 'middle') this.diseaseData[index].middle.push(obj)
            if(str == 'late') this.diseaseData[index].late.push(obj)
        },
        addDiseaseData() {
            var obj = {
                month: '',
                early: [],
                middle: [],
                late: []
            }
            this.diseaseData.push(obj)
        }
    }
}
</script>

<style scoped>
.menu_title {
    width: 100%;
    height: 146px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .setting_content {
      /* padding: 24px; */
      background: #fff;
  }
  .setting_title {
      padding: 24px;
      padding-bottom: 6px;
  }
  .setting_content_content {
      padding: 24px;
  }
  .bootom {
      width: calc(100% - 254px);
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 230px;
      background: #fff;
      margin-left: -24px;
      text-align: center;
      line-height: 44px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .disease-box {
    position: relative;
    
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px;
  }
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 50;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }

  .avatar-uploader {
    width: 150px;
    height: 162px;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 150px;
    height: 162px;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }
</style>